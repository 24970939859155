import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { updatePassword } from "./../slices/operaters";
import {
  enqueueSnackbar as enqueueSnackbarAction,
  closeSnackbar as closeSnackbarAction,
} from "../slices/notifier";
import { Button, CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import { openModal } from "../slices/modals";
const ButtonWithoutCorners = ({
  content,
  data,
  InviteOperator,
  className,
  setOperator,
  disabled,
  // passwords,
  onClick,
}) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { user } = useSelector((state) => state.user);
  const { statusInviteOperator } = useSelector((state) => state.operators);
  const enqueueSnackbar = (...args) => dispatch(enqueueSnackbarAction(...args));
  const closeSnackbar = (...args) => dispatch(closeSnackbarAction(...args));
  const showSnackbar = (data) => {
    // NOTE:
    // if you want to be able to dispatch a `closeSnackbar` action later on,
    // you SHOULD pass your own `key` in the options. `key` can be any sequence
    // of number or characters, but it has to be unique for a given snackbar.
    enqueueSnackbar({
      message: t(data.message),
      options: {
        key: new Date().getTime() + Math.random(),
        variant: data.variant,
        action: (key) => (
          <Button
            style={{ color: "white" }}
            onClick={() => closeSnackbar(key)}
            disabled={disabled}
          >
            {t("dismiss me")}
          </Button>
        ),
      },
    });
  };

  return (
    <button
      disabled={disabled}
      className={
        i18n.language === "ar"
          ? `invite-button left-rounded-button  ${className}`
          : `invite-button right-rounded-button ${className}`
      }
      style={
        className === "add-Operator" && i18n.language === "ar"
          ? { marginRight: "10px" }
          : { marginRight: "0px" }
      }
      onClick={() => {
        if (content.includes(t("Invite"))) {
          data.email.length !== 0 &&
            dispatch(
              InviteOperator({
                websiteID: user.websiteID,
                data: { email: data.email, role: data.role.value },
              })
            ).then((res) => {
              if (!res?.error) {
                showSnackbar({
                  variant: "success",
                  message: t(res?.payload?.message),
                });
                setOperator("");
              } else {
                showSnackbar({
                  variant: "error",
                  message: t(res?.error?.message),
                });
              }
            });
        } else if (content.includes("change")) {
          dispatch(updatePassword(passwords));
        } else if (
          content.includes(
            t(
              "Click To Check Your Bot Configuration And Customized By Your Own"
            )
          )
        ) {
          //  data.enabled && data.botConfigId
          // ?
          dispatch(
            openModal("add-operator", {
              id: "create-bot-config",
              websiteID: data?.websiteID,
            })
          );
          // : showSnackbar({
          //     variant: "warning",
          //     message: t("The Bot Must Be Enabled"),
          //   });
        } else if (
          content.includes(t("Create New User")) ||
          content.includes(t("Create New Department"))
        ) {
          dispatch(onClick);
        }
      }}
      type="button"
    >
      {content.includes("Invite") && statusInviteOperator === "loading" ? (
        <CircularProgress
          style={{ width: "16.67px", height: "16.67px", color: "#fff" }}
        />
      ) : (
        t(content)
      )}
    </button>
  );
};

export default ButtonWithoutCorners;
