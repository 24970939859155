import React from "react";
import { Redirect } from "react-router-dom";
import { useAuth } from "../hooks";
const AuthGuard = ({ children }) => {
  const { isAuthenticated } = useAuth();

  if (!isAuthenticated) {
    return <Redirect to="/login" />;
  }
  return <>{children}</>;
};

export default AuthGuard;
